.loading {
  margin-top: 20px;
  margin-bottom: 20px;
}

.toggle {
  display: flex;
  position: absolute;
  color: white;
  margin-top: 12px;
  margin-left: 275px;
  z-index: 1;
  color: black;
}
  
.toggle:hover {
  cursor: pointer;
}
