.modal-card-title {
  text-align: center;
  color: gray;
}
.modal-card-foot {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between !important;
}
