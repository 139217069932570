.inputStyle {
  margin: 0;
  color: black;
  color: #2ea689;
  font-size: 20px;
  font-weight: 700;
  min-width: 100% !important;
  background-color: inherit;
  border-radius: 5px;
  :disabled {
    border: none !important;
  }
}

.modalQrCode {
  display: flex;
  flex-direction: column;
}

.modalQrCodeButtons{
  display: flex;
  justify-content: space-around;
  cursor: pointer;
}