@import "https://cdn.jsdelivr.net/npm/bulma@0.9.4/css/bulma.min.css";
@import "https://cdn.jsdelivr.net/npm/bulma@0.9.4/css/bulma-rtl.min.css";

body {
  width: 100%;
  max-width: 100%;
  height: 100% !important;
}

html {
  margin: 0;
  padding: 0;
  max-width: 100%;
  height: 100%;
  max-height: 100% !important;
}

.paginate {
  display: flex;
  color: red;
}

.css-bevsf8-MuiPaper-root-MuiAlert-root {
  background-color: #ed6c02 !important;
  color: white !important;
  box-shadow: 5px 7px 5px -1px rgba(0, 0, 0, 0.19) !important;
}

.react-datepicker-popper {
  z-index: 9999 !important;
}

th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-sizeMedium.tss-10syd3x-MUIDataTableHeadCell-root.tss-gm6zfk-MUIDataTableHeadCell-fixedHeader.css-1ygcj2i-MuiTableCell-root {
  z-index: 1;
}

body {
  margin: 0;
  padding: 0;
  height: 100svh !important;
  flex-grow: 1 !important;
}

.powerBi {
  height: 90%;
  width: 100%;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.react-datepicker__input-container {
  border: none;
  margin: 0;
  padding: 0;
  align-items: center;
  justify-content: center;
  display: flex !important;
  align-self: center;
  justify-self: center;
}

.inputDate {
  justify-self: flex-end !important;
  align-self: center;
  border-bottom: 0.3em #2196f3 solid;
  text-align: center;
  display: flex;
  width: 55%;
  height: 100%;
  border-radius: 0.3em;
  padding-left: 1em;
  outline: 0;
  font-size: small;
}

.inputDate:valid {
  justify-self: flex-end !important;
  align-self: center;
  margin: 0;
  border-bottom: 0.3em #4caf50 solid;
  display: flex;
  width: 55%;
  height: 100%;
  border-radius: 0.3em;
  padding-left: 1em;
  outline: 0;
  font-size: small;
}

.inputDate-invalid {
  justify-self: flex-end !important;
  align-self: center;
  margin: 0;
  border-bottom: 0.3em red solid;
  display: flex;
  width: 55%;
  height: 100%;
  border-radius: 0.3em;
  padding-left: 1em;
  outline: 0;
  font-size: small;
}

.inputDate-invalid:valid {
  justify-self: flex-end !important;
  align-self: center;
  margin: 0;
  border-bottom: 0.3em #4caf50 solid;
  display: flex;
  width: 55%;
  height: 100%;
  border-radius: 0.3em;
  padding-left: 1em;
  outline: 0;
  font-size: small;
}
