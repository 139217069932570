.divPrincipal {
  overflow: auto;
  display: flex;
  margin: 0;
  flex-wrap: wrap;
  background-color: white;
}

.div2 {
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
  margin: 10px;
}

.div3 {
  display: flex;
  justify-content: space-between;
}

.divImg {
  padding: 10px;
  height: 150px;
  width: 120px;
  min-width: 120px;
  min-height: 150px;
  resize: both;
  overflow: hidden;
}

.img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.imgNotFound {
  height: 150px;
  width: 120px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.divAssinatura {
  overflow: auto;
  display: flex;
  margin: 0;
  padding: 0;
  flex-wrap: wrap;
  background-color: white;
}
