.accordionStyle {
  margin: 10px 0px 10px 0px;
  border-radius: 0 0 10px 10px;
  width: 100%;
}

.expandIcon {
  color: white;
}

.divAccodionSumary {
  display: flex;
  justify-content: space-between;
  width: 100%;
  text-align: initial;
  word-wrap: break-word;
  word-break: break-word;
}

.postMessageDiv {
  display: flex;
  flex-direction: column;
  height: 100svh;
  align-items: center;
  justify-content: space-evenly;
  padding: 10px;
}

.enableMessage {
  display: flex;
  flex-direction: column;
  height: 100svh;
  align-items: center;
  justify-content: center;
}

.mainDiv {
  padding: 10px 100px;
  text-align: initial;
  word-wrap: break-word;
  word-break: break-word;
  align-items: center;
  justify-content: center;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.imgLogoStyle {
  padding: 20px;
  max-height: 250;
  max-width: 250;
  width: auto;
  height: auto;
  align-self: center;
}

.spanInicio {
  font-weight: bold;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 1.3em;
}

.divPai {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 5% 0 5%;
}

.divPaiSubItem {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.subItemDiv {
  margin-top: 0.5em;
  width: inherit;
  align-self: center;
  padding: 0px 20px 0px 20px;
}

.accordionSubitem {
  margin-bottom: 0.5em;
  overflow: auto;
  overflow-x: hidden;
  min-width: 100%;
}

.accordionSummarySubitem {
  display: flex;
  justify-content: space-between;
  min-width: 100% !important;
  font-weight: bold;
}

.divSubItemNome {
  display: flex;
  justify-content: space-between;
  width: 100%;
  text-align: initial;
  word-wrap: break-word;
  word-break: break-word;
}
