.acordion {
  color: gray;
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  align-items: center;
  color: gray;
  margin-bottom: 1em;
}

.acordionSummary {
  font-size: 2em;
  color: gray;
  display: flex;
  width: 100% !important;
  /* align-items: center; */
  margin-bottom: 2em;
}

.filtersTitle {
  width: 100% !important;
  display: flex;
  justify-content: center;
  font-size: 1em;
  color: gray;
  margin-top: 10px;
}

.tagDiv {
  display: flex;
  width: 20em !important;
  flex-wrap: wrap;
  margin-top: 5px;
}

.skeletonSelect {
  height: 60px !important;
  width: 100% !important;
  margin-top: -1em !important;
}
