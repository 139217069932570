.dataList {
  width: 100% !important;
  min-width: 100% !important;
}

.headerStyle {
  display: flex;
  max-width: 100%;
  justify-content: space-between;
  flex-direction: column;
}

.headerModalDetail {
  thead {
    width: inherit;
  }
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px 0px 5px 0px;
  }
}

.th1 {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
}

.th2 {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
}

.tfoot {
  margin-top: 20px;
}

.trPrincipal {
  width: 100%;
}

.table {
  width: 100%;
}

.thPrincipal {
  word-break: break-all;
  font-family: sans-serif;
  font-size: 1.5em;
}

.h3 {
  font-size: 1.8em;
  font-weight: bolder;
  text-align: center;
}

.trContent {
  height: fit-content;
  width: 98%;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 0;
}

.thHeaderText {
  border: none !important;
  width: 50%;
  align-items: start;
  display: flex;
  flex-direction: column;
  p {
    font-size: 14px;
  }
  span {
    font-weight: 500;
  }
}
