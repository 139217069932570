.card {
  margin-bottom: 10px;
}

.container {
  padding-bottom: 100px;
}
.content {
  display: flex;
  justify-content: space-between;
}

.spanTitle {
  max-width: 75%;
  word-break: break-all;
  color: #47aa9c;
  font-size: 2em;
  font-weight: 600;
}

.divButton {
  display: flex;
  flex-direction: column;
}

.spanStatus {
  margin-top: 5px;
  color: gray;
  font-weight: 500;
}

.modal-card-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-weight: 600;
  text-align: center;
  word-break: break-all;
}

.modal-card-title {
  max-width: 90%;
  word-break: break-all;
  color: gray !important;
}

.delete {
  margin-left: auto;
  margin-right: 0;
  align-self: flex-start;
  background-color: red !important;
}

.divButtonsModal {
  display: flex;
  align-items: center;
}

.squareButtons {
  padding: 0;
  font-size: 2em;
  height: fit-content;
  margin-right: 2px;
}

.tagDiv {
  width: 100%;
  display: flex;
  justify-content: center;
}

.tagSpan {
  font-weight: 600;
}

.titleAccordeon {
  word-break: break-all;
  max-width: 70%;
  font-size: 2em;
  font-weight: 700;
  color: #2da88c;
}

.spanAcordeon {
  color: gray;
  word-break:break-all
}

.divTitleAcordeon {
  display: flex;
  flex-direction: column;
}

.divItensTitle {
  padding: 0px 8px 0px 8px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.acordeonDetail {
  color: gray;
  padding: 0px 30px 20px 30px;
}

.itensList {
  padding: 20px;
  display: flex;
  justify-content: space-between;
}

.buttonEye {
  padding: 5px !important;
  font-size: 1.2em !important;
  margin: 0;
  margin-right: 10px;
  height: fit-content !important;
}

.itemDetail {
  padding: 0px 0px 0px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style-position: inside;
}

.spanItemTitle {
  word-break: break-all;
  max-width: 50%;
  font-weight: 700;
}

.statusDiv {
  display: flex;
  justify-content: space-between;
}

.divtitleBtn {
  display: flex;
  align-items: center;
}

.buttons {
  width: 100%;
  display: flex !important  ;
  align-items: center !important;
  justify-content: center !important;
}

.control {
  display: flex;
  /* align-items: flex-start;
  justify-content: flex-start; */
  flex-direction: column !important;
}

.label {
  margin: 5px;
}

.accordeon {
  margin-bottom: 10px;
}

.spanTaskPendente {
  color: gray;
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
}

.skeletonDiv {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.divPagination {
  margin-bottom: 5px;
}

.li {
  margin-left: 20px;
}

.logList {
  list-style-position: inside;
  padding: 10px;
}

.imgStatus {
  object-fit: cover;
  margin-top: 2px;
  width: 150px;
  height: 150px;
}

.styleFinaliza {
  display: flex;
  justify-content: space-between;
  color: gray;
  font-size: 1.5em;
}

.divWarnings {
  display: flex;
  flex-direction: column;
  color: red;
  margin-left: 10px;
}

.divImageAndWarning {
  display: flex;
  align-items: center;
}

.documentStyle {
  font-size: 8em;
  position: relative;
  background-color: #f3f3f4;
  padding: -5px -5px -5px -5px;
  width: fit-content;
  height: fit-content;
  display: flex;
  margin: 0px 0px -5px 0px;
  align-items: center;
  justify-content: center;
}

.imgLink {
  margin-top: 8px;
  height: 120px;
  width: 90px;
  object-fit: cover;
}

.skeletonForImages {
  margin-top: -2.8em;
  margin-bottom: -2.8em;
}

.spanForDocs {
  font-size: 12px;
  padding-left: 5px;
  font-weight: 700;
  position: absolute;
}

.modal-card-foot {
  display: flex;
  align-items: center;
  justify-content: center;
}

.avaliaBox {
  display: flex;
  justify-content: space-around;
}

.spanInspetor {
  display: flex;
  width: 100%;
  font-size: 1.5em;
  color: gray;
  justify-content: center;
}

.inputSearch {
  margin-bottom: 5px;
}
